/**
 * Created by andrew.stalker on 02/10/2015.
 */

/* globals limitString, getSessionUser, getScannerLocationName, getProgramName, declarePopup, showPopup, searchArrayForObject,
 showAlert, clearAuthorisation, getCurrentPage, countStoredTransactions, getMaxTransactionCount, attachMenuNumbers, rackLimit,
 scanLocation, isPickingProgram, hasProgramParameter, getLabels, countTrailerCasks, beginPollingForAllocationChanges,
 generateChangeTrailerPopup, countBatchCasks, isBatchValid, pickingAllocation, persistPickingCask, findFirstCaskInBatch,
 batch_counter, barcodeToData, validateMake, updateTrailerContent, isSessionValid, alert, updateAllocationCounter, processScan,
 findCaskInAllocation, isTrailerFull, createBarcodeObject, storeTransaction, playSuccessSound, displayLastScan,
 getProgramWarehouseType, countTotalRequiredCasks, getBarcodeMapping, loadAllocationDetailPopup, getAllocationBatches,
 countTotalRequiredCasks, getProgramTransactionCode, getIncidentById, saveGenericTransaction, isOneOfThesePages, saveProgramParameters */

/**
 * @description Fixes focus to a UI element. Any key typing will be applied to the element.
 * @param {string} element DOM element selector
 */
function attachFixedFocus(element) {
  "use strict";
  // Different devices need to use different events. keypress is deprecated but we need to use it in Zetakey.
  console.log('[KEYS] Attaching fixed focus to element: ' + element);
  $(window).on(getKeyEventName('fixedFocus'), function() {
    if(!$(element).is(":focus")){
      console.log('[KEYS] ' + element + ' acquiring focus');
      $(element).focus();
    }
  });
}

/**
 * @description Detaches fixed focus from the page.
 */
function detachFixedFocus() {
  "use strict";
  // Different devices need to use different events. keypress is deprecated but we need to use it in Zetakey.
  console.log('[KEYS] Detaching fixed focus.');
  $(window).off(getKeyEventName('fixedFocus'));
}

function disableBackButton() {
  "use strict";
  // Different devices need to use different events. keypress is deprecated but we need to use it in Zetakey.
  $(document).on(getKeyEventName('backButton'), function(event) {
    if ((isAndroid && (event.key === 'Backspace' || event.keyCode === 8)) ||
        (!isAndroid && event.keyCode === 8)) {
      console.log('[KEYS] Back button pressed and preventing default action.');
      event.preventDefault();
    }
  });
}

function enableBackButton() {
  "use strict";
  // Different devices need to use different events. keypress is deprecated but we need to use it in Zetakey.
  $(document).off(getKeyEventName('backButton'));
}

function attachInputEvents() {
	"use strict";
  $(":input").on("focus", function() {
    console.log('[KEYS] Enabling the back button.');
    enableBackButton();
  }).on("focusout",function() {
    console.log('[KEYS] Disabling the back button.');
    disableBackButton();
  });
}

/**
 * Builds the header elements on the screen
 * @param {boolean} menuButton True if the header requires a menu button.
 * @return {boolean} True if the screen is built correctly and all program parameters are valid.
 */
function buildScreen(menuButton) {
  "use strict";
  // Before we can design the screen we need to setup program parameters for this program we are on.
  // Clear the old session storage for reload-navigation. This will be set correctly in saveProgramParameters.
  clearReloadProgramKey();
  // If we are on the following pages we must not set program parameters.
  // Location - When first used, the page is acessed directly and no menu item key is used / set.
  // Menu - We do not set for the menu, and if the user back navigates here it doesnt matter.
  if (!isOneOfThesePages(['dsmenu','dslocation','dscache'])) {
    // The page is a program and must have some parameters to be set from a menu key ID.
    if (!saveProgramParameters()) {
      // If this fails to return true, there was an error here and we must halt any execution here.
      return false; // throw false to act on a failure to setup the program parameters correctly.
    }
  }

  // set the page title and add the exit button to the top of the screen.
  $('#pageTitle').html( getProgramName());
  if (typeof menuButton === "undefined") {
    menuButton = false;
  }
  var headerElement = $('#header');
  if (menuButton) {
    headerElement.append('<button name="exitbutton" class="btn btn-dramscan btn-lg" id="exitbutton"  role="button" onclick="location.href=\'dsmenu.php\'">Menu</button>');
  }

	// set the username, and location name
  $("#nameCell").html(limitString(getSessionUser(), 15));
  $("#locationCell").html(limitString(getScannerLocationName(), 20));

  // setup the code for the popup menu.
	declarePopup("#popupMenuModal", "#popupMenuCloseButton");
  // click function for the online button
  $("#onlinestatusbutton").on("mousedown", function () {
		showPopup("#popupMenuModal");
    //loadPopupMenu(menuButton);
  });

  //Prevent back button usage
  disableBackButton();
  attachInputEvents();

	//Check for authorisations locking the scanner
  if(localStorage.getItem("runtime/authorisation")) {
    //Hold for alert.
    var awaitingAuthorisation = localStorage.getObject("runtime/authorisation");
    var thisIncident = getIncidentById(awaitingAuthorisation.incident);

    showAlert("Scanner Locked: " + thisIncident.description,{auth:thisIncident.restricted,
      authUser:awaitingAuthorisation.incidentUser,
      approvalRequired:(localStorage.getVariable("settings/useIncidentApproval") === "true"),
      callback:function(passed,username) {
      if(passed) {
        clearAuthorisation(username);
        if(getCurrentPage() === "dsmenu") {
          attachMenuNumbers();
        }
      }
    }});
  }

	// Check for page and transaction count.
	switch (getCurrentPage()) {
		case "dsmenu":
		case "dslogin":
		case "dsallocation":
		case "dsdespatch":
		case "dssettings":
		case "dslocation":
		case "dstransaction":
		case "dsusage":
		case "dscontainer":
		  break;
		default:
			countStoredTransactions(function(noOfTransactions) {
				if (noOfTransactions >= getMaxTransactionCount()) {
          showAlert("Maximum allowed transactions have been scanned. Please upload.", {callback: function() {
						document.location.href = './dsmenu.php';
					}});
          return false;
				}
			});
	}
  return true;
}

/**
 * @description Creates scroll buttons for a scrolling container, added to the DOM before the container.
 * @param {String} containerName - Name of the container (excluding the #).
 * @param {Number} [amount] - The amount in pixels. default is 150.
 */
function createScrollButtonsForContainer(containerName, amount) {
	"use strict";
  var code = '<button data-role="button" class="btn btn-dramscan divscroll dv-up" id="divScrollUp">' +
    '<i class="glyphicon glyphicon-arrow-up"></i></button>' +
    '<button data-role="button" class="btn btn-dramscan divscroll dv-down" id="divScrollDown">' +
    '<i class="glyphicon glyphicon-arrow-down"></i></button>';
  var scrollUp = (amount)? ("-="+amount+"px"):"-=150px";
  var scrollDown = (amount)? ("+="+amount+"px"):"+=150px";

  var element = "#" + containerName;
  $(element).before(code);

  $("#divScrollUp").on("click", function (event) {
    event.preventDefault();
		$(element).animate({
      scrollTop: scrollUp
    });
  });

  $("#divScrollDown").on("click", function (event) {
    event.preventDefault();
		$(element).animate({
      scrollTop: scrollDown
    });
  });
}

function showNotification(message) {
	"use strict";
  $("#actionNotification").html(message).show().fadeTo(2000, 500).slideUp(500, function() {
    $("#actionNotification").hide();
  });
}

function showErrorNotification(message) {
	"use strict";
  $("#actionErrorNotification").html(message).show().fadeTo(2000, 500).slideUp(500, function() {
    $("#actionErrorNotification").hide();
  });
}

/**
 * @description Builds the Labelling screen used by Wild Turkey
 * @param {String} warehouseType
 */
var buildLabellingScreen = function (warehouseType) {
  "use strict";
  var labels = getLabels();
  //create elements and add to the programContainer.
  var code = "";
  if (warehouseType === "RACK") {
    code = '<div class="form-group row"><div class="col-xs-6"><label for="batch">Batch:</label>' +
      '<input type="text" id="batch" class="input-lg form-control"></div>' +
      '<div class="col-xs-6"><label for="batch">Make:</label>';

    //select
    code += '<select id="make" class="input-lg form-control dramscan-dropdown">';
    /** @typedef {Object} Make
     *  @property {string} makeCode - Alpha numeric make code
     *
     * @type {[Make]} makes */
    var makes = localStorage.getObject("data/makes");
    var optionCode = "";
    var currentMake = (localStorage.getItem("scanMake")) ? localStorage.getItem("scanMake") : "";
    if (currentMake === "") {
      optionCode += '<option selected >Choose...</option>';
    }
    for (var i = 0; i < makes.length; i++) {
      var selected = (currentMake === makes[i].makeCode) ? "selected" : "";
      optionCode += '<option value="' + makes[i].makeCode + '" ' + selected + '>' + makes[i].makeCode + '</option>';
    }
    code += optionCode + '</select>';

    code += '</div></div>';
  } else {
    var batchList = getAllocationBatches();
    code = '<div class="form-group"><label for="batch">Batch:</label>';
    code += '<select id="batch" class="input-lg form-control dramscan-dropdown">';

    var batchOptionCode = "";
    var currentBatch = (localStorage.getItem("scanBatch")) ? localStorage.getItem("scanBatch") : "";
    if (currentBatch === "") {
      batchOptionCode = '<option selected>Choose...</option>';
    }
    for (var b = 0; b < batchList.length; b++) {
      var selectedBatch = (currentBatch === batchList[b]) ? "selected" : "";
      var batchInfo = countBatchCasks(batchList[b]);
      if (batchInfo.labelled === batchInfo.total) {
        selectedBatch += ' disabled="disabled"';
      }
      batchOptionCode += '<option value="' + batchList[b] + '" ' + selectedBatch + '>' + batchList[b] + '</option>';
    }

    code += batchOptionCode + '</select>';

    code += '</div>';
  }

  code += '<div class="form-group"><label for="scanInput">Scan ' + labels.caskLabel + ':</label><input type="text" id="scanInput" class="input-lg form-control"></div>';
  code += '<table class="table"><thead>';
  var buttonCode = "", labelType = "";

  if (warehouseType === "RACK") {
    labelType = "racked";
    code += '<tr><th>Whs</th><th>' + labels[labelType].bay + '</th>' +
      '<th>' + labels[labelType].rack + '</th><th>' + labels[labelType].level + '</th>' +
      '<th>F/B</th><th>Scans</th></tr></thead>' +
      '<tbody><tr><td>' + scanLocation.warehouse + '</td><td>' + scanLocation.bay + '</td>' +
      '<td>' + scanLocation.rack + '</td><td>' + scanLocation.level + '</td>' +
      '<td>' + scanLocation.direction + '</td><td id="scansField">0</td></tr>';
    buttonCode = '<button class="btn btn-dramscan btn-lg btn-block" id="changeLocationButton" onclick="reloadProgram()">Change Location</button>';
  } else if (warehouseType === "PALL") {
    labelType = "pallet";
    var trailerInfo = countTrailerCasks(localStorage.getItem("scanTrailer"));
    //calculate cask requirement
    var casksRequired = (pickingAllocation.hasOrderLines) ? countTotalRequiredCasks(pickingAllocation.orderLines, pickingAllocation.casks): pickingAllocation.casks.length;
    code += '<tr><th>Allocation</th><th>Trailer</th><th>Batch</th></tr></thead>';
    code += '<tbody><tr><td id="allocationInfo">' + pickingAllocation.number + ' <div id="counterlabel">' + pickingAllocation.scanned +'/'+casksRequired+'</div></td><td id="scanTrailerInfo">' + localStorage.getItem("scanTrailer") + ' (' + trailerInfo.content + '/' + trailerInfo.capacity + ')</td><td id="batchInfo"></td></tr>';
    buttonCode = '<button class="btn btn-dramscan btn-lg btn-block" id="changeTrailerButton">Change Trailer</button>';
  }
  code += '</tbody></table>';

  if (isPickingProgram()) {
    var barcodeMap = getBarcodeMapping();
    code += '<table class="table"><thead><tr>';

    if (barcodeMap.batch.length > 0) code += '<th>Batch</th>';
    if (barcodeMap.make.length > 0) code += '<th>Make</th>';
    if (barcodeMap.fillYear.length > 0) code += '<th>Year</th>';
    if (barcodeMap.rotation.length > 0) code += '<th>Rot No</th>';
    if (barcodeMap.caskNumber.length > 0) code += '<th>' + labels.caskLabel + '</th>';

    code += '</tr></thead><tbody id="casktablebody"></tbody></table>';

  } else {
    code += '<table class="table"><thead><tr><th>Last ' + labels.caskLabel + ':</th></tr></thead><tbody id="casktablebody"></tbody></table>';
  }
  code += buttonCode;

  $("#programContainer").html(code);

  var focusElement = "#batch";

  if (localStorage.getItem("scanBatch")) {
    $("#batch").val(localStorage.getItem("scanBatch").toUpperCase());
    focusElement = "#scanInput";
  }

  if (warehouseType === "RACK") {
    createKeyAction("#batch", "#make");
    createKeyAction("#make", "#scanInput");

    $("#make").change(function () {

			if (validateMake($("#make").val().toUpperCase())) {
        localStorage.setVariable("scanMake", $("#make").val().toUpperCase(), false);
      } else {
        showAlert("Invalid Make",{focus:"make"});
        return false;
      }
    });

  } else {
    createKeyAction("#batch", "#scanInput");

    $("#batch").change(function () {
      var newBatch = $("#batch").val();
      localStorage.setVariable("scanBatch", newBatch, true);

      var batchCount = countBatchCasks(newBatch);
      batch_counter = batchCount; // jshint ignore:line
      $("#batchInfo").html(newBatch + " (" + batchCount.labelled + "/" + batchCount.total + ")");
    });
  }

  $(focusElement).focus();
  createEnterAction("#batch", false, function(newBatch) {
    //enter pressed
    if (isPickingProgram()) {
      var batch = (localStorage.getItem("scanBatch")) ? localStorage.getItem("scanBatch") : "";
      newBatch = newBatch.toUpperCase();
      if (newBatch === "") {
        showAlert("Enter Batch",{focus:"batch"});
      }
      if (batch !== newBatch) {
        localStorage.setVariable("scanBatch", newBatch, true);
        if (isPickingProgram()) {
          var batchCount = countBatchCasks(newBatch);
          batch_counter = batchCount; // jshint ignore:line
          if (batchCount.total === 0) {
            $("#batch").val("");
            showAlert("This batch is not on the Allocation.",{focus:"batch"});
          }
          $("#batchInfo").html(newBatch + " (" + batchCount.labelled + "/" + batchCount.total + ")");
        }
      }
      if (isPickingProgram()) {
        if (batch_counter.labelled === batch_counter.total) {
          showAlert("Batch Full.",{focus:"batch"});
        }
      }
    } else {
      // rick labelling - validate the batch against mapping if exists.
      if (isBatchValid(newBatch)) {
        $("#batch").val(newBatch.toUpperCase());
        localStorage.setVariable("scanBatch", newBatch.toUpperCase(), true);
      } else {
        $("#batch").val("");
        showAlert("Invalid batch",{focus:"batch"});
      }
    }
    return false;
  });

  if (warehouseType === "PALL") {
    $("#changeTrailerButton").on("click", function () {
      generateChangeTrailerPopup(function (validTrailer) {
        if (validTrailer) {
          // update ui
          var trailerInfo = countTrailerCasks(localStorage.getItem("scanTrailer"));
          $("#scanTrailerInfo").html(localStorage.getItem("scanTrailer") + ' (' + trailerInfo.content + '/' + trailerInfo.capacity + ')');
          $("#batch").focus();
        }
      });
    });
  }

  createEnterAction("#scanInput", true, function(scannedCask) {

    //todo additional check do disallow swa barcode.??
    var barcodeObject = createBarcodeObject(scannedCask, "scanInput");
    if (!barcodeObject.passed) {
      return;
    }

    //Custom check to see if the TAG is within a set range
    //IF needed in other programs in future then this will be moved to a post check in
    //createBarcodeObject, run for all WTL types in the switch statement.
    var programParameters = sessionStorage.getVariable("program/parameters").split("|"); // should be tidy with program.js
    var min = programParameters.indexOf("MIN");
    if(min >= 0) {
      var minVal = Number(programParameters[min+1]);
      if(barcodeObject.displayCode < minVal) {
        //below limit
        showAlert("ID is below minimum value of "+minVal,{focus:"scanInput"});
        return;
      }
    }
    var max = programParameters.indexOf("MAX");
    if (max >= 0) {
      var maxVal = Number(programParameters[max+1]);
      if(barcodeObject.displayCode > maxVal) {
        //above limit
        showAlert("ID is above maximum value of "+maxVal,{focus:"scanInput"});
        return;
      }
    }

    var batch = (localStorage.getItem("scanBatch")) ? localStorage.getItem("scanBatch") : "";
    var newBatch = $("#batch").val().toUpperCase();
    if (newBatch === "") {
      showAlert("Enter Batch",{focus:"batch"});
      return;
    }
    if (batch !== newBatch) {
      localStorage.setVariable("scanBatch", newBatch, true);
      if (isPickingProgram()) {
        var batchCount = countBatchCasks(newBatch);
        batch_counter = batchCount; // jshint ignore:line
        if (batchCount.total === 0) {
          $("#batch").val("");
          showAlert("This batch is not on the Allocation.",{focus:"batch"});
          return;
        }
        $("#batchInfo").html(newBatch + " " + batchCount.labelled + "/" + batchCount.total);
      } else {
        $("#batch").val(newBatch);
      }
    }

    if (warehouseType === "RACK") {
      if (!isBatchValid($("#batch").val())) {
        $("#batch").val("");
        showAlert("Invalid Batch",{focus:"batch"});
        return;
      }
      if (validateMake($("#make").val().toUpperCase().trim())) {
        if (!localStorage.getItem("scanMake")) {
          localStorage.setVariable("scanMake", $("#make").val().toUpperCase(), true);
        }
      } else {
        showAlert("Invalid Make",{focus:"make"});
        return;
      }
    }

    if (isPickingProgram()) {

      if (batch_counter.labelled === batch_counter.total) {
        showAlert("Batch Full.",{focus:"batch"});
        return;
      }

      if (findCaskInAllocation(barcodeObject.caskId) >= 0) {
        showAlert("ID already linked.",{focus:"scanInput"});
        return;
      }

      if (isTrailerFull(localStorage.getItem("scanTrailer"))) {
        showAlert("The trailer Should be full.",{type:"info"});
      }
      //so from here the batch obviously wasn't full, assign record
      var recordMatch = findFirstCaskInBatch(newBatch);
      if (recordMatch >= 0) {
        pickingAllocation.casks[recordMatch].caskId = barcodeObject.caskId;
        pickingAllocation.casks[recordMatch].scanstatus += ((pickingAllocation.casks[recordMatch].scanstatus === "") ? "" : ",") + getProgramTransactionCode();
        persistPickingCask(recordMatch);
        barcodeObject.barcode = pickingAllocation.casks[recordMatch].barcode;
        batch_counter.labelled++;
        $("#batchInfo").html(newBatch + " " + batch_counter.labelled + "/" + batch_counter.total);
        // update trailer.
        var newTrailerInfo = updateTrailerContent(localStorage.getItem("scanTrailer"));
        if (newTrailerInfo.content === newTrailerInfo.capacity) {
          showAlert("Trailer should now be full.",{type:"info"});
        }
        $("#scanTrailerInfo").html(localStorage.getItem("scanTrailer") + ' (' + newTrailerInfo.content + '/' + newTrailerInfo.capacity + ')');
      } else {
        alert("ERROR FINDING RECORD");
        return;
      }
    }

    if(!isSessionValid()) return; // validate session before saving any data.
    if (warehouseType === "RACK") {
      processScan(scannedCask, function(){});
    } else {

      // create swa data as it must exist by now.
      barcodeObject.swa = barcodeToData(barcodeObject.barcode);
      updateAllocationCounter();
      //save transaction.
      saveGenericTransaction(barcodeObject, null, "", function (success) {
        if (success) {
          //scan sound if on
          playSuccessSound(true);
          //output details to the screen.
          displayLastScan(barcodeObject);
        }
      });
    }
  });
  attachInputEvents();
};

/**
 * @description creates an event listener for the enter key. moves focus. Parameters should include JQuery selector.
 * @param {string} invokeElement the element which to bind the event listener to.
 * @param {string} targetElement the element to focus on after enter is pressed.
 **/
function createKeyAction(invokeElement, targetElement) {
	"use strict";
  //Clear anything from before to be sure
  $(invokeElement).off("keypress");
  // Different devices need to use different events. keypress is deprecated but we need to use it in Zetakey.
  console.log('[KEYS] Creating a key action for element: ' + invokeElement + ' to: ' + targetElement);
  $(invokeElement).on(getKeyEventName(''), function (event) {
    if ((isAndroid && (event.key === 'Enter' || event.keyCode === 13)) ||
        (!isAndroid && event.keyCode === 13)) {
      $(targetElement).focus();
      return false; // to prevent default actions
    }
  });
}

/**
 * @description creates an event listener for the enter key. performs a click action on another element.
 * @param {string} invokeElement the element which to bind the event listener to. Must include JQuery selector.
 * @param {string} targetElement the element which to call the click function on. Must include JQuery selector.
 **/
function createCompleteAction(invokeElement, targetElement) {
	"use strict";
  //Clear anything from before to be sure
  // todo always off keypress??
  $(invokeElement).off("keypress");
  // Different devices need to use different events. keypress is deprecated but we need to use it in Zetakey.
  console.log('[KEYS] Creating a complete action for element: ' + invokeElement + ' to: ' + targetElement);
  $(invokeElement).on(getKeyEventName(''), function (event) {
    if ((isAndroid && (event.key === 'Enter' || event.keyCode === 13)) ||
        (!isAndroid && event.keyCode === 13)) {
      $(targetElement).click();
      return false; // to prevent default actions
    }
  });
}

/**
 * @description Creates and event listener for the enter key. Calls the callback function provided.
 * @param {string} invokeElement the element which to bind the event listener to. Must include jQuery selector type.
 * @param {Boolean} clearOnEnter Clear the input if enter was pressed.
 * @param callback
 */
function createEnterAction(invokeElement, clearOnEnter, callback) {
	"use strict";
	var thisElement = $(invokeElement);
	// Different devices need to use different events. keypress is deprecated but we need to use it in Zetakey.
	var eventName = getKeyEventName('enterAction');
	thisElement.off(eventName);
  console.log('[KEYS] Creating an enter action for element: ' + invokeElement);
	thisElement.on(eventName, function (event) {
		if ((isAndroid && (event.key === 'Enter' || event.keyCode === 13)) ||
            (!isAndroid && event.keyCode === 13)) {
			//13 is enter key. Go.
          console.log('[KEYS] Enter pressed on element: ' + invokeElement);
			var elementValue = thisElement.val();
			if (elementValue === "") return;
			if (clearOnEnter) thisElement.val("");
			callback(elementValue);
		}
	});
}

/**
 * @description Provides a way to detach the enter action events from an element.
 * @param {string} invokeElement the element which to remove the event listener from. Must include jQuery selector type.
 */
function clearEnterAction(invokeElement) {
  "use strict";
  $(invokeElement).off(getKeyEventName('enterAction'));
}

/**
 * @description Get the event name for attaching key events to an input element.
 * @param {string} label - The unique event label for a specific function, allows more than one function to be attached.
 * @return {string} - The event name that will be used in the construction of an event listener.
 */
function getKeyEventName(label) {
  "use strict";
  // If keypress fails due to deprecation then something will need to work it out. keydown is the new standard.
  // Example: var eventName = (isAndroid) ? 'keydown.enterAction' : 'keypress.enterAction';
  var eventName = (isAndroid) ? 'keydown' : 'keypress';
  // However all current scanners should support keypress.
  if (label === "") {
    return eventName;
  } else {
    return eventName + "." + label;
  }
}
